<script>
import api from '@/api/index.js'
import tool, { deepFn } from '@/tool'
import base from '@/templates/list'
export default {
  directives: {},
  filters: {},
  components: {
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: true, title: '新增' },
        remove: { show: true, title: '删除' },
        edit: { show: true, title: '编辑' },
        view: { show: false, title: '详情' },
        enable: { show: true, title: 'global.上线' },
        valid: { show: true, title: 'global.下线' }
      },
      showList: [
        {
          copName: 'el-input',
          placeholder: this.$t('Consult.title'),
          key: 'title',
          name: '',
          attrs: ['placeholder']
        },
        {
          copName: 'el-cascader',
          placeholder: 'list.类别',
          key: 'categoryId',
          attrs: ['placeholder', 'cascader', 'options', 'props', 'filterable'],
          filterable: true,
          props: { checkStrictly: true, value: 'id', label: 'name', children: 'node', emitPath: false },
          cascaderOptions: [
          ]
        },
        // {
        //   copName: 'el-select',
        //   key: 'status',
        //   type: '',
        //   attrs: ['placeholder', 'options'],
        //   placeholder: 'list.状态',
        //   options: [
        //     { label: this.$t('global.上线'), value: '0' },
        //     { label: this.$t('global.下线'), value: '1' }
        //   ]
        // }
      ],
      tableTile: [
        {
          columnLabel: '序号',
          width: '80',
          type: 'index'
        },
        {
          columnLabel: '图片',
          prop: 'bannerImgUrl',
          width: '',
          formatter: (row, column, cellValue, index) => {
            const h = this.$createElement
            return h('el-image', {
              attrs: {
                previewSrcList: [cellValue],
                src: cellValue
              },
              style: {
                width: '50px',
                height: '50px'
              }
            })
          }
        },
        {
          columnLabel: '语言类型',
          prop: 'langType',
          width: '',
          formatter: (row, column, cellValue, index) => cellValue === 1 ? '中文' : 'English'
        },
        {
          key: 'title',
          columnLabel: 'list.标题',
          prop: 'title',
          width: ''
        },
        {
          key: 'categoryName',
          columnLabel: 'list.类别',
          prop: 'categoryName',
          width: ''
        },
        {
          key: 'updateTime',
          columnLabel: 'list.修改时间',
          prop: 'updateTime',
          width: '',
          formatter: (row, column, cellValue, index) => this.$moment(cellValue).format('YYYY-MM-DD HH:mm:ss')
        },
        {
          key: 'createUserName',
          columnLabel: 'list.操作人',
          prop: 'createUserName',
          width: ''
        },
        {
          key: 'status',
          columnLabel: 'list.状态',
          prop: 'status',
          width: '',
          formatter: (row, column, cellValue, index) => {
            let temp = ''
            switch (cellValue) {
              case 0:
                temp = this.$t('global.上线')
                break
              case 1:
                temp = this.$t('global.下线')
                break
            }
            return temp
          }
        }
      ]
    }
  },
  created () {
    api.consult.queryTypeTree().then(res => {
      this.showList[1].cascaderOptions = deepFn(res.data)
    })
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true

      const query = {
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline
        }
      }
      api.consult.queryPage(query)
        .then((res) => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records
            this.formInline.page.total = res.data.total
          }
          this.loading = false
        })
    },

    // 新增
    add () {
      this.$router.push('/companyDynamic-add')
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      const row = this.tableData.find(item => item.id === id)
      await api.consult.remove({ id: id, associatedId: row.associatedId })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    },

    // 编辑
    async edit (id) {
      const row = this.tableData.find(item => item.id === id)
      this.$router.push('/companyDynamic-add/' + id + `/${row.associatedId}`)
    },
    // 启用
    async enable (id) {
      await this.$confirm(this.$t('global.enableTooltip').replace('停用', '下线'), { type: 'warning' })
      const row = this.tableData.find(item => item.id === id)
      await api.consult.updateStatusById({ id: id, status: 1, associatedId: row.associatedId })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    },

    // 停用
    async disable (id) {
      await this.$confirm(this.$t('global.disableTooltip').replace('启用', '上线'), { type: 'warning' })
      const row = this.tableData.find(item => item.id === id)
      await api.consult.updateStatusById({ id: id, status: 0, associatedId: row.associatedId })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    }
  }
}
</script>
